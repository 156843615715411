import {Menu} from "../../ui/layout/Menu";
import React, {useEffect, useState} from "react";
import {Breadcrumb} from "../../ui/Breadcrumb";
import useApi from "../../hooks/useApi";
import {userState} from "../../store";
import {useSnapshot} from "valtio";
import {FooterCmp} from "../../ui/layout/Footer";
import {ApiError, Team} from "../../@backend";

export const BonusTip = () => {
    const userSnap = useSnapshot(userState);
    const { backend } = useApi(true);
    const [teams, setTeams] = useState<Team[]>([] as Team[]);
    const [bonusQuestion, setBonusQuestion] = useState<number>(userSnap.userProfile.bonusQuestionAnswer);
    const [errorMessage, setErrorMessage] = useState<string|null>(null);
    const [successMessage, setSuccessMessage] = useState<string|null>(null);


    useEffect(() => {
        if(userSnap.isLoggedIn) {
            backend.teams.teamsControllerGetAllTeams(process.env.REACT_APP_ACTIVE_SPORT).then((res) => {
                setTeams(res);
            });
        }
    }, [userSnap.isLoggedIn]);

    if(!userSnap.isLoggedIn) {
        return(<><h1>Je vyžadováno přihlášení</h1></>);
    }

    const sendBonusQuestion = () => {
        setSuccessMessage(null);
        setErrorMessage(null);
        backend.user.userControllerAnswerBonusQuestion({answer: Number(bonusQuestion)}).then((res) => {
            setSuccessMessage('Odpověď byla úspěšně odeslána');
            userState.userProfile.bonusQuestionAnswer = Number(bonusQuestion);
        }).catch((e: ApiError) => {
            setErrorMessage(e.body.error.message);
        });

    }

    return (
        <>
            <Menu />
            <Breadcrumb text={'Uživatel / Bonusová otázka'} showLogo={true} />
            <div className="container mx-auto p-4">
                <div className="flex flex-col gap-4">
                    <div
                        className="bg-blue-200 border-t-4 border-blue-400 rounded-b text-teal-900 px-4 py-3 shadow-md"
                        role="alert">
                        <div className="flex">
                            <div className="py-1">
                                <svg className="fill-current h-6 w-6 text-blue-500 mr-4"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path
                                        d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                                </svg>
                            </div>
                            <div>
                                <p className="font-bold">Bonusová otázka</p>
                                <p className="text-sm">
                                    Každý máte možnost odpovědět na bonusovou otázku a získat tak bonusové body. (+50 bodů) <br/>
                                    Bonusové body se započítávají do celkového pořadí a můžou zamíchat žebříčkem. <br/>
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="flex flex-col gap-4  card bg-gray-50 dark:bg-black p-4">
                        <h1 className="text-2xl font-bold">Bonusová otázka:</h1>
                        <div className="text-3xl"> Kdo vyhraje <span className={'font-bold'}>EURO 2024</span>? <br/></div>
                        <div>
                            {errorMessage ? (
                                <div className='flex flex-col bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative' role='alert'>
                                    <strong className='font-bold'>Chyba!</strong>
                                    <span className="block sm:inline">{errorMessage}</span>
                                </div>
                            ) : null}
                            {successMessage ? (
                                <div className='flex flex-col bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative' role='alert'>
                                    <span className="block sm:inline">{successMessage}</span>
                                </div>
                            ) : null}
                        </div>
                        <div className={'flex flex-row gap-2'}>
                            <select className={'dark:bg-black'} onChange={ (e) => setBonusQuestion(Number(e.target.value))}>
                                <option>Vyberte tým</option>
                                {teams.map((team, index) => (
                                    <option key={index} value={team.id} selected={bonusQuestion === team.id} className={'flex flex-row items-center'}>
                                            {team.nameCountry}
                                    </option>
                                ))}
                            </select>
                            <button onClick={sendBonusQuestion} className={'btn btn-primary bg-blue-500 px-4 py-1 text-white rounded-2xl'}>Uložit</button>
                        </div>
                    </div>
                </div>
            </div>
            <FooterCmp/>
        </>
    );
};
