import { Modal } from 'flowbite-react';
import { useSnapshot } from 'valtio'
import { appState } from '../store'
import { useState } from 'react'
import useApi from '../hooks/useApi'
import {AiFillSave} from "react-icons/ai";
import {ApiError, CloseMatch} from "../@backend";

export default function CloseMatchModal() {
  const appSnap = useSnapshot(appState);
  // const userSnap = useSnapshot(userState);
  const { backend } = useApi(true);


  const [homeScore, setHomeScore] = useState(0);
  const [awayScore, setAwayScore] = useState(0);

  const [overtime, setOvertime] = useState(false);

  const [overtimeHomeScore, setOvertimeHomeScore] = useState(null);
  const [overtimeAwayScore, setOvertimeAwayScore] = useState(null);

  const [formErrors, setFormErrors] = useState([] as any);
  // const [isSuccess, setIsSuccess] = useState(false);

  const match = appSnap.selectedMatch;

  const handleHomeScore = (e: any) => {
    setHomeScore(Number(e.target.value));
  };

  const handleAwayScore = (e: any) => {
    setAwayScore(Number(e.target.value));
  };

  const handleOvertime = (e: any) => {
    setOvertime(e.target.value);
  };

  const handleOvertimeHomeScore = (e: any) => {
    setOvertimeHomeScore(Number(e.target.value));
  };

  const handleOvertimeAwayScore = (e: any) => {
    setOvertimeAwayScore(Number(e.target.value));
  };


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setFormErrors([]);
      const closeMatch = {
        id: match.id,
        scoreHomeTeam: homeScore,
        scoreAwayTeam: awayScore,
      } as CloseMatch;
      if(overtime) {
        closeMatch.overtime = true;
        closeMatch.overtimeScoreHomeTeam = overtimeHomeScore;
        closeMatch.overtimeScoreAwayTeam = overtimeAwayScore;
      }
      backend.matches.matchControllerCloseMatch(closeMatch).then(async (res) => {
        console.log('Match closed');
        appState.modals.showCloseMatchModal = false;
        appState.matches = await backend.matches.matchControllerGetAllMatchesPersonalized();
        setHomeScore(null);
        setAwayScore(null);
      }).catch((e: ApiError) => {
        setFormErrors([e.body.error.message]);
      });

    } catch (e) {
      if(e.status === 401) {
        // setFormErrors(['Neplatné přihlašovací údaje']);
      }
    }

  };

  if(!match) return null;

  return (
        <>
        {/*{match && (*/}
        <Modal show={appSnap.modals.showCloseMatchModal} onClose={() => appState.modals.showCloseMatchModal = false}>
          <form onSubmit={handleSubmit}>
          <Modal.Header className={'h-14'}>Uzavření zápasu</Modal.Header>
          <Modal.Body>


            <div className="flex flex-col w-full space-y-6">

              {formErrors.length > 0 && (
                  <div
                      className='flex flex-col bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
                      role='alert'>
                    <strong className='font-bold'>Problém s uložením zápasu!</strong>
                    <span className="block sm:inline">{formErrors.join(', ')}</span>
                  </div>
              )}
              <div className='flex flex-row pt-5'>
                <div className='flex flex-col w-1/2'>
                  <div className={'flex flex-row place-content-center'}>
                    <img src={'/images/flags/' + match.teamHome.flag} alt={match.teamHome.name} className='w-32 h-20'/>
                  </div>
                  <div className={'text-center dark:text-white'}>{match.teamHome.nameCountry}</div>
                  <div className={'text-3xl text-center'}>
                    <input type="number" min={0} max={999} defaultValue={homeScore} onChange={handleHomeScore}
                           className={'w-1/2 h-10 text-center text-black dark:text-white dark:bg-neutral-900 dark:border-black'}/>
                  </div>
                </div>
                <div className='flex flex-col w-1/2'>
                  <div className={'flex flex-row place-content-center pla'}>
                    <img src={'/images/flags/' + match.teamAway.flag} alt={match.teamAway.name} className='w-32 h-20'/>
                  </div>
                  <div className={'text-center dark:text-white'}>{match.teamAway.nameCountry}</div>
                  <div className={'text-3xl text-center'}>
                    <input type="number" min={0} max={999} defaultValue={awayScore} onChange={handleAwayScore}
                           className={'w-1/2 h-10 text-center text-black dark:text-white dark:bg-neutral-900 dark:border-black'}/>
                  </div>
                </div>
              </div>
              <div className='flex flex-row items-center gap-2'>
                <input type="checkbox" onChange={handleOvertime} className={''}/>
                <span className={'font-bold'}>Prodloužení</span>
              </div>

              {overtime && (
                <div className='flex flex-row'>
                  <div className='flex flex-col w-1/2'>
                    <div className={'text-3xl text-center'}>
                      <input type="number" min={0} max={999} defaultValue={overtimeHomeScore} onChange={handleOvertimeHomeScore}
                             className={'w-1/2 h-10 text-center'}/>
                    </div>
                  </div>
                  <div className='flex flex-col w-1/2'>
                    <div className={'text-3xl text-center'}>
                      <input type="number" min={0} max={999} defaultValue={overtimeAwayScore} onChange={handleOvertimeAwayScore}
                             className={'w-1/2 h-10 text-center'}/>
                    </div>
                  </div>
                </div>
              )}

            </div>
          </Modal.Body>
            <Modal.Footer className='flex flex-row items-center place-content-center h-14'>
              <button type='submit' className="default-button"><AiFillSave/> Uložit</button>
            </Modal.Footer>
          </form>
        </Modal>
        {/*) }*/}
        </>
  )
}
