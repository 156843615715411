import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from "./pages/Home";
import LoginModal from "./modals/LoginModal";
import {Rules} from "./pages/Rules";
import {EntryFee} from "./pages/user/EntryFee";
import {Contact} from "./pages/Contact";
import {Ladder} from "./pages/Ladder";
import RegistrationModal from "./modals/RegistrationModal";
import CloseMatchModal from "./modals/CloseMatchModal";
import {BonusTip} from "./pages/user/BonusTip";
import {Gdpr} from "./pages/Gdpr";
import {UserProfile} from "./pages/user/UserProfile";
import AddMatchModal from "./modals/AddMatchModal";
import {MyProfile} from "./pages/user/MyProfile";

function App() {

  return (
      <>
          <div className={'min-h-screen dark:bg-neutral-900 dark:text-white'}>
              <Router>
                  <Routes>
                      {/*<Route path="/"  element={<Home/>}/>*/}
                      <Route path="/"  element={<Ladder/>}/>
                      <Route path="/zebricek" element={<Ladder/>}/>
                      <Route path="/pravidla" element={<Rules/>}/>
                      <Route path="/gdpr" element={<Gdpr />}/>
                      <Route path="/kontakt" element={<Contact/>}/>

                      <Route path="/uzivatel/poplatek" element={<EntryFee/>}/>
                      <Route path="/uzivatel/bonusova-otazka" element={<BonusTip />}/>
                      <Route path="/uzivatel/profil/muj-profil" element={<MyProfile />}/>
                      <Route path="/uzivatel/profil/:id" element={<UserProfile />}/>
                </Routes>
                  <CloseMatchModal/>
                  <AddMatchModal />
                  <LoginModal />
                  <RegistrationModal />
            </Router>
      </div>
    </>
  );
}

export default App;
