import { Modal } from 'flowbite-react';
import { useSnapshot } from 'valtio'
import {appState, userState} from '../store'
import React, {useEffect, useState} from 'react'
import useApi from '../hooks/useApi'
import {AiFillSave} from "react-icons/ai";
import {AddMatch, ApiError, Team} from "../@backend";

export default function AddMatchModal() {
  const appSnap = useSnapshot(appState);
  const userSnap = useSnapshot(userState);

  const { backend } = useApi(true);

  const [teams, setTeams] = useState<Team[]>([] as Team[]);
  const [homeTeam, setHomeTeam] = useState(0);
  const [awayTeam, setAwayTeam] = useState(0);

  const [matchTime, setMatchTime] = useState<string>('');

  const [formErrors, setFormErrors] = useState([] as any);

  useEffect(() => {

    if(userSnap.isLoggedIn) {
      backend.teams.teamsControllerGetAllTeams(process.env.REACT_APP_ACTIVE_SPORT).then((res) => {
        setTeams(res);
      });
    }


  }, [userSnap.isLoggedIn]);


  const handleHomeScore = (e: any) => {
    setHomeTeam(Number(e.target.value));
  };

  const handleAwayScore = (e: any) => {
    setAwayTeam(Number(e.target.value));
  };

  const handleMatchTime = (e: any) => {
    setMatchTime(new Date(e.target.value).toISOString());
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setFormErrors([]);

      if(homeTeam === 0 || awayTeam === 0) {
        setFormErrors(['Vyberte týmy']);
        return;
      }
      if(matchTime === '') {
        setFormErrors(['Vyberte čas zápasu']);
        return;
      }

      const newMatch = {
        teamHomeId: homeTeam,
        teamAwayId: awayTeam,
        matchTime: matchTime,
      } as AddMatch;


      backend.matches.matchControllerAddMatch(newMatch).then(async (res) => {
        console.log(res);
        appState.modals.showAddMatchModal = false;
        appState.matches = await backend.matches.matchControllerGetAllMatchesPersonalized();
      }).catch((e: ApiError) => {
        setFormErrors([e.body.error.message]);
      });
    } catch (e) {
      if(e.status === 401) {
        // setFormErrors(['Neplatné přihlašovací údaje']);
      }
    }

  };

  // if(!match) return null;

  return (
        <Modal show={appSnap.modals.showAddMatchModal} onClose={() => appState.modals.showAddMatchModal = false}>
          <form onSubmit={handleSubmit}>
          <Modal.Header className={'h-14 dark:bg-black'}>Přidat zápas</Modal.Header>
          <Modal.Body className={'dark:bg-black'}>

            <div className="flex flex-col w-full space-y-6">

              {formErrors.length > 0 && (
                  <div
                      className='flex flex-col bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
                      role='alert'>
                    <strong className='font-bold'>Problém s uložením zápasu!</strong>
                    <span className="block sm:inline">{formErrors.join(', ')}</span>
                  </div>
              )}
              <div className='flex flex-row pt-5 gap-4 items-center'>
                <div className='flex flex-col w-1/2'>
                  <div className={'flex flex-col place-content-center items-center'}>
                    <select onChange={handleHomeScore} className={'w-full dark:bg-black dark:text-white'}>
                      <option className={'flex flex-row items-center'}>Vyberte domácí tým</option>
                        {teams.map((team, index) => (
                            <option key={index} value={team.id} className={'flex flex-row items-center'}>
                              {team.nameCountry}
                            </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div>vs</div>
                <div className='flex flex-col w-1/2'>
                  <div className={'flex flex-col place-content-center items-center'}>
                    <select onChange={handleAwayScore} className={'w-full dark:bg-black dark:text-white'}>
                      <option className={'flex flex-row items-center'}>Vyberte venkovní tým</option>
                      {teams.map((team, index) => (
                          <option key={index} value={team.id} className={'flex flex-row items-center'}>
                            {team.nameCountry}
                          </option>
                      ))}
                    </select>
                  </div>

                </div>
              </div>

              <div className={'flex flex-col place-content-center items-center'}>
                <input onChange={handleMatchTime} type={'datetime-local'} className={'dark:bg-black dark:text-white'}  />
              </div>
            </div>
          </Modal.Body>
            <Modal.Footer className='flex flex-row items-center place-content-center h-14 dark:bg-black'>
              <button type='submit' className="default-button" onClick={handleSubmit}><AiFillSave /> Uložit</button>
            </Modal.Footer>
          </form>
        </Modal>
  )
}
