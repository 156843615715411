/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompareTipWithRelations } from '../models/CompareTipWithRelations';
import type { TipWithRelations } from '../models/TipWithRelations';
import type { User } from '../models/User';
import type { UserWithRelations } from '../models/UserWithRelations';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LadderService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param id
     * @returns CompareTipWithRelations Return public profile of a user in the ladder competition and compare it with the current user.
     * @throws ApiError
     */
    public ladderControllerCompareUserWithMe(
        id: string,
    ): CancelablePromise<Array<CompareTipWithRelations>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ladder/compare/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns any Prize money for the ladder competition.
     * @throws ApiError
     */
    public ladderControllerGetLadderPrizeMoney(): CancelablePromise<{
        prizeMoney?: number;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ladder/prize-money',
        });
    }
    /**
     * @param id
     * @returns any Return public profile of a user in the ladder competition
     * @throws ApiError
     */
    public ladderControllerGetUserInLadder(
        id: string,
    ): CancelablePromise<{
        profile?: UserWithRelations;
        tips?: Array<TipWithRelations>;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ladder/user/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns User Array of User model instances
     * @throws ApiError
     */
    public ladderControllerGetAllUsersInLadder(): CancelablePromise<Array<User>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/ladder',
        });
    }
}
