"use client";
import {Footer, Tooltip} from "flowbite-react";
import useApi from "../../hooks/useApi";
import React, {useEffect, useState} from "react";
import {userState} from "../../store";
import {useSnapshot} from "valtio";

export function FooterCmp() {
    const userSnap = useSnapshot(userState);
    const [time, setTime] = useState<string|null>(null);
    const { backend } = useApi();

    useEffect(() => {
        backend.timeController.timeControllerGetSystemTime().then((res) => {
            setTime(new Date(res.datetime).toLocaleString());
        });
    }, []);

    return (
        <Footer container className={'sticky top-[100vh] rounded-none pt-5 dark:bg-black dark:border-gray-700 dark:text-white'}>
            <div className="w-full text-center">
                <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
                    {userSnap.dark ? (
                        <Footer.Brand
                            href="https://workbox.cz"
                            src="https://workbox.cz/images/logo.png"
                            alt="Workbox logo"
                        />
                    ) : (
                        <Footer.Brand
                            href="https://workbox.cz"
                            src="https://workbox.cz/images/logo_black.png"
                            alt="Workbox logo"
                        />
                    )}
                    <Footer.LinkGroup>
                        <Footer.Link href="https://workbox.cz">workbox.cz</Footer.Link>
                        <Footer.Link href="https://workbox.cz">o nás</Footer.Link>
                        <Footer.Link href="https://tipbuddy.cz/gdpr">gdpr</Footer.Link>
                    </Footer.LinkGroup>
                </div>
                <Footer.Divider />
                <div className={'flex flex-col'}>
                    <div className={'text-xs accent-gray-100 hover:underline'}><a href={'mailto:sara@tipbuddy.cz'}>sara@tipbuddy.cz</a></div>
                    <Footer.Copyright href="#" by="tipbuddy.cz" year={2024} />
                </div>
            </div>
        </Footer>
    );
}
