/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddMatch } from '../models/AddMatch';
import type { CloseMatch } from '../models/CloseMatch';
import type { Match } from '../models/Match';
import type { MatchWithRelations } from '../models/MatchWithRelations';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MatchesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns Match Retun new Match instance
     * @throws ApiError
     */
    public matchControllerAddMatch(
        requestBody?: AddMatch,
    ): CancelablePromise<Match> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/matches/add',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any Return value of MatchController.closeMatch
     * @throws ApiError
     */
    public matchControllerCloseMatch(
        requestBody?: CloseMatch,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/matches/close',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param viewPlayedMatches
     * @returns MatchWithRelations Array of Match model instances
     * @throws ApiError
     */
    public matchControllerGetAllMatchesPersonalized(
        viewPlayedMatches?: boolean,
    ): CancelablePromise<Array<MatchWithRelations>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/matches/personalized',
            query: {
                'viewPlayedMatches': viewPlayedMatches,
            },
        });
    }
    /**
     * @returns MatchWithRelations Array of Match model instances
     * @throws ApiError
     */
    public matchControllerGetAllMatches(): CancelablePromise<Array<MatchWithRelations>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/matches',
        });
    }
}
