export const Breadcrumb = ({text, showLogo}) => {
    return (
        <div className="flex flex-row bg-blue-500 py-2 lg:px-2">
            <div
                className="flex lg:inline-flex p-1 items-center text-indigo-100 leading-none mr-3">
                {showLogo && (
                    <span className="flex text-xs font-bold mr-3">
                        {/*<img src='/images/logos/iihf.png' className="h-16" alt="IIHF Logo"/>*/}
                        <img src='/images/logos/euro_2024.png' className="h-16" alt="Euro 2024 Logo"/>
                    </span>
                )}
                <span className="flex rounded-full bg-blue-500 uppercase px-2 py-1 text-2xl font-bold mr-3">
                    {text}
                </span>
                {/*<svg className="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">*/}
                {/*    <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/>*/}
                {/*</svg>*/}
            </div>
        </div>
    );
};
