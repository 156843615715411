import {Menu} from "../../ui/layout/Menu";
import React, {useEffect, useState} from "react";
import {Breadcrumb} from "../../ui/Breadcrumb";
import useApi from "../../hooks/useApi";
import {appState, userState} from "../../store";
import {useSnapshot} from "valtio";
import {FooterCmp} from "../../ui/layout/Footer";
import {ApiError, CompareTipWithRelations, TipWithRelations, User, UserWithRelations} from "../../@backend";
import {Alert, Modal, Tooltip} from "flowbite-react";
import {RiAiGenerate} from "react-icons/ri";
import {GiLoad, GiRegeneration} from "react-icons/gi";
import {AiFillSave} from "react-icons/ai";

export const MyProfile = () => {

    const userSnap = useSnapshot(userState);
    const apiUrl = process.env.REACT_APP_API_URL ?? 'http://localhost:3000'
    const {backend } = useApi(true);
    const [user, setUser] = useState<UserWithRelations|null>(null);
    const [avatar, setAvatar] = useState<string|null>(null);
    const [isGeneratingAvatar, setIsGeneratingAvatar] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);

    //Modal
    const [generateAvatarModal, setGenerateAvatarModal] = useState<boolean>(false);
    const [prompt, setPrompt] = useState<string>('');
    const loadProfile = () => {
        backend.user.userControllerMyProfile().then((res) => {
            setUser(res);
            setAvatar(apiUrl + '/avatars/' + res.avatar);
            console.log(res);
        }).catch((err: ApiError) => {
            setErrorMessage(err.body.error.message);
        });
    }

    useEffect(() => {
        if(userSnap.isLoggedIn) {
            loadProfile();
        }
    },[userSnap.isLoggedIn]);

    if(!userSnap.isLoggedIn) {
        return(<><h1>Je vyžadováno přihlášení</h1></>);
    }


    const generateAvatar = () => {
        console.log('Generating avatar');
        setGenerateAvatarModal(false);
        setIsGeneratingAvatar(true);
        backend.user.userControllerGenerateAvatar(prompt).then((res) => {
            setAvatar(res.avatarUrl);
            userState.userProfile = {...userState.userProfile, ...{avatar: userSnap.userProfile.id + '.png?'+new Date().getTime()}};
            setIsGeneratingAvatar(false);
        }).catch((err: ApiError) => {
            setErrorMessage(err.body.error.message);
            setIsGeneratingAvatar(false);
        });
    }

    const submitForm = (e: React.FormEvent) => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const formData = new FormData(form);
        const userUpdated = {
            firstname: formData.get('firstname') as string,
            lastname: formData.get('lastname') as string,
            email: formData.get('email') as string,
            phone: formData.get('phone') as string,
        } as User;

        backend.user.userControllerUpdateMyProfile(userUpdated).then((res) => {
            setErrorMessage(null);
            setSuccess(true);
            userState.userProfile = {...userState.userProfile, ...userUpdated};
        }).catch((err: ApiError) => {
            setSuccess(false);
            setErrorMessage(err.body.error.message);
        });
    };

    return (
        <>
            <Menu />
            <Breadcrumb text={'Uživatel / Profil'} showLogo={true} />
            <div className="container mx-auto p-1 md:p-4">
                {errorMessage && (
                    <Alert color="warning" rounded>
                        <span className="font-medium">Chyba!</span> {errorMessage}
                    </Alert>
                )}
                {success && (
                    <Alert color="info" rounded>
                        <span className="font-medium">Uloženo</span> Změny byly uloženy
                    </Alert>
                )}

                {user && (
                    <div className="card bg-gray-50 p-4 dark:bg-black dark:text-white">
                        <div className="flex flex-col items-center md:flex-row gap-4">
                            <div className="w-1/3 flex flex-col place-items-center">
                                {avatar ? (
                                    <div className="relative inline-flex items-center justify-center w-36 h-36 overflow-hidden bg-gray-300 rounded-full dark:bg-gray-600">
                                        <img src={avatar} alt="avatar" className="w-full h-full object-cover" />
                                    </div>
                                ): (
                                    <div className="relative inline-flex items-center justify-center w-36 h-36 overflow-hidden bg-gray-300 rounded-full dark:bg-gray-600">
                                    <span className="font-medium text-gray-600 dark:text-gray-500">{user.firstname?.charAt(0)}{user.lastname?.charAt(0)}</span>
                                    </div>
                                )}

                                <button onClick={ () => setGenerateAvatarModal(true)} disabled={isGeneratingAvatar} className={'flex flex-row items-center gap-1 mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'}>
                                    {isGeneratingAvatar ?
                                        <><GiLoad /> Generuji avatar...</>
                                        :
                                        <><RiAiGenerate /> Vygenerovat avatar</>
                                    }
                                </button>
                            </div>

                            <div className="w-2/3 flex flex-col gap-2">
                                <form onSubmit={submitForm} className={'w-full flex flex-col items-center md:items-start gap-2'}>
                                    <div className={'flex flex-row items-center gap-2'}>
                                        <label htmlFor={'firstname'} className={'font-bold w-28'}>Jméno:</label>
                                        <input name={'firstname'} type={'text'} defaultValue={user.firstname} className={'dark:bg-black dark:text-white rounded'}/>
                                    </div>
                                    <div className={'flex flex-row items-center gap-2'}>
                                        <label htmlFor={'lastname'} className={'font-bold w-28'}>Příjmení:</label>
                                        <input name={'lastname'} type={'text'} defaultValue={user.lastname}
                                               className={'dark:bg-black dark:text-white rounded'}/>
                                    </div>
                                    <div className={'flex flex-row items-center gap-2'}>
                                        <label htmlFor={'email'} className={'font-bold w-28'}>Email:</label>
                                        <input name={'email'} type={'text'} defaultValue={user.email}
                                               className={'dark:bg-black dark:text-white rounded'}/>
                                    </div>
                                    <div className={'flex flex-row items-center gap-2'}>
                                        <label htmlFor={'phone'} className={'font-bold w-28'}>Telefon:</label>
                                        <input name={'phone'} type={'text'} defaultValue={user.phone}
                                               className={'dark:bg-black dark:text-white rounded'}/>
                                    </div>

                                    <div className={'w-1/2 flex flex-row place-items-center place-content-center'}>
                                        <button type={'submit'} className={'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'}>
                                            Uložit změny
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <FooterCmp/>

            <Modal show={generateAvatarModal} onClose={() => setGenerateAvatarModal(false)}>
                    <Modal.Header className={'h-14 dark:bg-black'}>Generovat avatar</Modal.Header>
                    <Modal.Body className={'dark:bg-black'}>
                        <div className={'flex flex-row gap-2 items-center'}>
                        <label htmlFor={'prompt'} className={'text-black dark:text-white'}>Popište sebe v Angličtině</label>
                        <input type='text' name={'prompt'} className={'text-black dark:text-white dark:bg-black rounded'} defaultValue={''} onChange={(e) => {setPrompt(e.target.value)}} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='flex flex-row items-center place-content-center h-14 dark:bg-black'>
                        <button onClick={generateAvatar} className="default-button"><AiFillSave /> Generovat</button>
                    </Modal.Footer>
            </Modal>
        </>
    );
};
