import PodiumStep from './PodiumStep'
import React, {useEffect} from "react";
import {User} from "../../@backend";
import useApi from "../../hooks/useApi";
import {useSnapshot} from "valtio";
import {userState} from "../../store";

export default function Podium({ winners }) {


    const userSnap = useSnapshot(userState);
    // const [users, setUsers] = React.useState<User[]>([]);
    const [podium, setPodium] = React.useState<User[]>([]);
    const {backend} = useApi();

    const loadLadder = () => {
        backend.ladder.ladderControllerGetAllUsersInLadder().then((resUsers) => {
            resUsers = resUsers.map((winner, position) => ({ ...winner, position }));
            resUsers = resUsers.slice(0, 5);

            const podium = [8, 6, 4, 2, 0, 1, 3, 5, 7, 9]
                .reduce((podiumOrder, position) => [...podiumOrder, resUsers[position]], [])
                .filter(Boolean);
            // console.log(resUsers);
            console.log(podium);
            setPodium(podium);
            // setUsers(resUsers);
        });
    }

    useEffect(() => {
        if(userSnap.isLoggedIn && userSnap.userProfile.entryFeePaid) {
            loadLadder();
        }
    },[userSnap.isLoggedIn]);

    return (
        <div style={{
                display: 'grid',
                gridAutoFlow: 'column dense',
                gap: '.5rem',
                marginTop: '2rem',
                justifyContent: 'center',
                justifyItems: 'center',
                alignContent: 'flex-end',
                alignItems: 'flex-end',
                borderBottom: '1px solid #e5e7eb',
                height: 250
            }}>
            {podium.length > 0 && podium.map((winner: User) => (
                <PodiumStep key={winner.id} podium={podium} winner={winner} />
            ))}
        </div>
    )
}
