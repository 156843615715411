import {Menu} from "../ui/layout/Menu";
import React from "react";
import {Breadcrumb} from "../ui/Breadcrumb";
import {FooterCmp} from "../ui/layout/Footer";
import {JokerButton} from "../ui/buttons/JokerButton";

export const Gdpr = () => {
    return (
        <>
            <Menu />
            <Breadcrumb text={'GDPR'} showLogo={true} />
            <div className="container mx-auto p-4 card bg-gray-50 dark:bg-black rules">
                Registrací udělujete souhlas se zpracováním osobních údajů, jméno a emailová adresa, Adamu Šmídovi ,
                Stan. Masara 1331, 75201 Kojetín (dále jen správce) za účelem účasti v soutěži – Neveřejná tipovací soutěž Euro 2024, a to na dobu jednoho roku.
                <br/><br/>
                Osobní údaje bude správce zpracovávat manuálně i automatizovaně.
                <br/><br/>
                Poučení o právech Subjektu údajů vztahujících se k výše uvedenému souhlasu se zpracováním osobních údajů
                Udělení souhlasu je dobrovolné. Tento souhlas můžete kdykoli odvolat, a to pro kterýkoli z výše
                uvedených účelů zpracování. Odvolání souhlasu je možné provést na info@tipbuddy.cz.
                <br/><br/>
                Máte právo přístupu ke svým osobním údajům zpracovávaných Správcem, jejich opravu nebo výmaz, popřípadě
                omezení zpracování, a právo vznést námitku proti zpracování.
                <br/><br/>
                Dále máte právo získat od Správce osobní údaje, které se Vás týkají a jež jste Správci
                poskytnul/poskytla na základě tohoto souhlasu. Správce Vám na základě Vaší žádosti poskytne údaje bez
                zbytečného odkladu ve strukturovaném, běžně používaném a strojově čitelném formátu nebo je na Vaši
                žádost poskytne jinému jednoznačně určenému správci. Toto právo se nevztahuje na osobní údaje, které
                nejsou zpracovávány automatizovaně.
                <br/><br/>
                Domníváte-li se, že dochází k neoprávněnému zpracování Vašich osobních údajů, můžete se obrátit se
                stížností na dozorový orgán, kterým je pro území České republiky Úřad pro ochranu osobních údajů
                (www.uoou.cz).
            </div>
            <FooterCmp/>
        </>
    );
};
