import {Avatar, Dropdown, Navbar, Tooltip} from "flowbite-react";
import React, {useEffect} from "react";
import {useSnapshot} from "valtio";
import {appState, userState} from "../../store";
import {UserLoginButton} from "../buttons/UserLoginButton";
import {UserRegisterButton} from "../buttons/UserRegisterButton";
import {Link, NavLink} from "react-router-dom";
import {AiFillExclamationCircle} from "react-icons/ai";
import {IoMoon, IoSunny} from "react-icons/io5";

export const Menu = () => {
    const userSnap = useSnapshot(userState);
    const apiUrl = process.env.REACT_APP_API_URL ?? 'http://localhost:3000'

    const logOut = () => {
        userState.isLoggedIn = false;
        userState.token = null;
        userState.userProfile = {};
    }

    const getMenuClasses = (isActive: boolean, isPending: boolean) => {
        let def = 'p-2 md:p-0 md:hover:bg-white hover:underline dark:hover:bg-black dark:text-white dark:hover:text-white';
        if(isActive) {
            def += ' font-bold text-gray-900';
        }
        if(isPending) {
            def += ' animate-pulse';
        }
        return def;
    }

    const darkModeHandler = () => {
        userState.dark = !userSnap.dark;
        document.body.classList.toggle("dark");
    }

    return (
        <>
            <Navbar fluid className={'bg-white px-2 py-2.5 sm:px-4 dark:border-gray-700 dark:bg-black dark:text-white'}>
                <Navbar.Brand href="/">
                    {userSnap.dark ? (
                        <img src={'/images/logos/tipbuddy_logo.png'} className={'w-20 sm:w-32 md:w-32'} alt={'TipBuddy logo'}/>
                    ) : (
                        <img src={'/images/logos/tipbuddy_logo_black.png'} className={'w-20 sm:w-32 md:w-32'} alt={'TipBuddy logo'}/>
                    )}
                </Navbar.Brand>
                <div className="flex flex-row md:order-2 gap-2">

                    <button onClick={() => darkModeHandler()}>
                        <Tooltip content={'Přepnutí režimu zobrazení'}>
                        {userSnap.dark && <IoSunny className={'text-2xl'}/>
                        }
                        {!userSnap.dark && <IoMoon className={'text-2xl'}/> }
                        </Tooltip>
                    </button>

                    {userSnap.isLoggedIn ? (
                        <Dropdown arrowIcon={false} inline className={'outlineImportant'}
                                  label={
                                      <div className={'flex flex-row items-center gap-2 outlineImportant'}>

                                          <div className={userSnap.userProfile.entryFeePaid ? 'flex flex-col' : 'flex flex-row items-center gap-2'}>
                                        <span className="block text-sm font-bold">{userSnap.userProfile.firstname} {userSnap.userProfile.lastname}</span>
                                              {!userSnap.userProfile.entryFeePaid ? (
                                                  <Tooltip
                                                      content={'Nemáte zaplacený vstupní příspěvěk. Po kliku na avatar'}><AiFillExclamationCircle
                                                      className={'text-3xl text-red-500'}/></Tooltip>
                                              ) : (
                                                  <div className={'text-sm'}>
                                                      {userSnap.userProfile.points} bodů
                                                  </div>
                                              )}

                                          </div>
                                          {userSnap.userProfile.bonusQuestionAnswer === null && (
                                              <Tooltip
                                                  content={'Nemáte vyplňenou bonusovou otázku'}><AiFillExclamationCircle
                                                  className={'text-3xl text-blue-600'}/></Tooltip>
                                          )}
                                          {/*<Avatar alt="User settings" img="/default-avatar.png" rounded/>*/}
                                          {userSnap.userProfile.avatar ? (
                                              <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-300 rounded-full dark:bg-gray-600">
                                                  <img src={apiUrl + '/avatars/' + userSnap.userProfile.avatar} alt="avatar" className="w-10 h-10 object-cover" />
                                              </div>
                                              ):(
                                              <div
                                                  className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-300 rounded-full dark:bg-gray-600">
                                              <span
                                                  className="font-medium text-gray-600 dark:text-gray-500">{userSnap.userProfile.firstname?.charAt(0)}{userSnap.userProfile?.lastname?.charAt(0)}</span>
                                              </div>
                                          )}

                                      </div>
                                  }>
                            <Dropdown.Header>
                                <span
                                    className="block text-sm">{userSnap.userProfile.firstname} {userSnap.userProfile.lastname}</span>
                                <span className="block truncate text-sm font-medium">{userSnap.userProfile.email}</span>
                            </Dropdown.Header>
                            <Link to={'/uzivatel/profil/muj-profil'} className={'outlineImportant'}>
                                <Dropdown.Item className={'flex flex-row gap-2 items-center'}>
                                    <span>Můj profil</span>
                                </Dropdown.Item>
                            </Link>
                            <Link to={'/uzivatel/poplatek'} className={'outlineImportant'}>
                                <Dropdown.Item className={'flex flex-row gap-2 items-center'}>
                                    <span>Registrační poplatek</span>
                                    {!userSnap.userProfile.entryFeePaid && (
                                        <AiFillExclamationCircle className={'text-3xl text-red-500'}/>
                                    )}
                                </Dropdown.Item>
                            </Link>
                            <Link to={'/uzivatel/bonusova-otazka'} className={'outlineImportant'}>
                                <Dropdown.Item className={'flex flex-row gap-2 items-center'}>
                                    <span>Bonusová otázka</span>
                                    {userSnap.userProfile.bonusQuestionAnswer === null && (
                                        <AiFillExclamationCircle className={'text-3xl text-blue-600'}/>
                                    )}
                                </Dropdown.Item>
                            </Link>
                            <Dropdown.Divider/>
                            <Dropdown.Item onClick={logOut}>Odhlásit</Dropdown.Item>
                        </Dropdown>

                    ) : (
                        <>
                            <UserLoginButton/>
                            <UserRegisterButton/>
                        </>
                    )}
                    <Navbar.Toggle/>
                </div>
                <Navbar.Collapse>
                    <NavLink to={'/'}
                             className={({isActive, isPending}) => getMenuClasses(isActive, isPending)}>Zápasy</NavLink>
                    <NavLink to={'/zebricek'} className={({ isActive, isPending }) => getMenuClasses(isActive, isPending)}>Žebříček</NavLink>
                   <NavLink to={'/pravidla'} className={({ isActive, isPending }) => getMenuClasses(isActive, isPending)}>Pravidla</NavLink>
                   <NavLink to={'/kontakt'} className={({ isActive, isPending }) => getMenuClasses(isActive, isPending)}>Kontakt</NavLink>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
};
