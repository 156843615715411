import { proxy, subscribe } from 'valtio'
import {MatchWithRelations, User} from "./@backend";
import useApi from "./hooks/useApi";

const appState = proxy({
  title: 'Vítejte na ',
  titlePerex: ' | TipBuddy.cz',
  debug: true,
  selectedMatch: undefined,
  modals: {
    showLoginModal: false,
    showRegisterModal: false,
    showCloseMatchModal: false,
    showAddMatchModal: false,
  },
  matches: [] as MatchWithRelations[],
});

// @ts-ignore
const userState = proxy(JSON.parse(localStorage.getItem('tipbuddy-user')) || {
  isLoggedIn: false,
  token: null,
  dark: false,
  userProfile: {} as User,
} as any);

if(userState.dark) {
    document.body.classList.add('dark');
}

const loadProfile = () => {
  const token = localStorage.getItem('token');
  if(token) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {backend} = useApi(true);

    backend.user.userControllerMyProfile().then((user: User) => {
      userState.userProfile = user;
    }).catch((e: any) => {
      // console.log('Error loading user', e);
      if(e.status === 401 || e.status === 404) {
        console.log('User not found');
        localStorage.removeItem('token');
        localStorage.removeItem('tipbuddy-user');
        userState.isLoggedIn = false;
        userState.token = null;
        userState.userProfile = {};
        return;
      }
    });
  }
}
loadProfile();

subscribe(userState, () => {
  localStorage.setItem('tipbuddy-user', JSON.stringify(userState));
})

export { userState, appState }
