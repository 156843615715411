import {Menu} from "../../ui/layout/Menu";
import React, {useEffect, useState} from "react";
import {Breadcrumb} from "../../ui/Breadcrumb";
import useApi from "../../hooks/useApi";
import {userState} from "../../store";
import {useSnapshot} from "valtio";
import {FooterCmp} from "../../ui/layout/Footer";

export const EntryFee = () => {
    const userSnap = useSnapshot(userState);
    const [paymentQRCode, setpaymentQRCode] = useState<string>('');
    const { backend } = useApi(true);


    useEffect(() => {

        if(userSnap.isLoggedIn) {
            backend.user.userControllerUserPaymentQrCode().then((res) => {
                setpaymentQRCode(res);
            });
        }

    }, [userSnap.isLoggedIn]);

    if(!userSnap.isLoggedIn) {
        return(<><h1>Je vyžadováno přihlášení</h1></>);
    }


    const downloadPaymentQRcode = () => {
        const link = document.createElement('a');
        link.href = paymentQRCode;
        link.download = 'tipbuddy_qrplatba.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };




    return (
        <>
            <Menu />
            <Breadcrumb text={'Uživatel / Vstupní poplatek'} showLogo={true} />
            <div className="container mx-auto p-4">
                <div className="flex flex-col gap-4">
                        <div
                            className="bg-blue-200 border-t-4 border-blue-400 rounded-b text-teal-900 px-4 py-3 shadow-md"
                            role="alert">
                            <div className="flex">
                                <div className="py-1">
                                    <svg className="fill-current h-6 w-6 text-blue-500 mr-4"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <path
                                            d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                                    </svg>
                                </div>
                                <div>
                                    <p className="font-bold">Vstupní poplatek</p>
                                    <p className="text-sm">
                                        Vstupní poplatek je určen hlavně na výhry pro účastníky, část peněz na provoz systému. <br/>
                                        Po zaplacení vstupného získáte možnost tipovat zápasy a získávat body do žebříčku. <br/>
                                    </p>
                                </div>
                            </div>
                        </div>

                    {!userSnap.userProfile.entryFeePaid ? (
                    <div className="flex flex-col card bg-gray-50  p-4 dark:bg-black dark:text-white">
                        <h1 className="text-2xl font-bold">Způsob platby</h1>
                        <div className="flex flex-col md:flex-row gap-4">
                            <div className={'flex flex-col place-items-center'}>
                                <img src={paymentQRCode} className={'w-1/2 md:w-full'} alt={'QR platba'} />
                                <p className={'flex italic text-center text-xs'}>QR Platba</p>
                                <button className={'btn btn-primary bg-blue-500 px-4 py-1 text-white rounded-2xl'} onClick={downloadPaymentQRcode}>Stáhnout QR kód</button>
                            </div>
                            <div>
                            <p>
                                    Cena je poplatku je stanovena na <span className={'font-bold'}>100 Kč</span>. <br/>
                                    Vstupné je možné zaplatit na účet <span
                                    className={'font-bold'}>340013836/0300</span><br/>
                                    Variabliní symbol platby je: <span className={'font-bold'}>{userSnap.userProfile.entryFeeVS}</span>
                                    <br/>
                                    <span className={'text-sm italic'}>Platbu je možné odevzdat i osobně pověřené osobě.</span>
                                </p>
                                <br/>
                                <p className="text-gray-600 dark:text-gray-300">
                                    Po připsání platby na účet systém automaticky zaznamená zaplacení vstupného a
                                    zpřístupní tipování zápasů. (Připsání může trvat až 3 hodiny)
                                </p>
                            </div>
                        </div>
                    </div>
                    ): (
                        <div className="flex flex-col card bg-gray-50 dark:bg-black p-4">
                            <h1 className="text-2xl font-bold">Vstupní poplatek byl zaplacen</h1>
                            <p className="text-gray-600">
                                Vstupní poplatek byl zaplacen a máte možnost tipovat zápasy a získávat body do žebříčku.
                            </p>
                            <p className="text-blue-600 text-xl">
                                Děkujeme.
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <FooterCmp />
        </>
    );
};
