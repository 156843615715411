import {Menu} from "../../ui/layout/Menu";
import React, {useEffect, useState} from "react";
import {Breadcrumb} from "../../ui/Breadcrumb";
import useApi from "../../hooks/useApi";
import {userState} from "../../store";
import {useSnapshot} from "valtio";
import {FooterCmp} from "../../ui/layout/Footer";
import {useParams} from "react-router";
import {ApiError, CompareTipWithRelations, TipWithRelations, User, UserWithRelations} from "../../@backend";
import {Alert, Tooltip} from "flowbite-react";
import {JokerButton} from "../../ui/buttons/JokerButton";
import {GrCompare} from "react-icons/gr";
import {MdCompare} from "react-icons/md";

export const UserProfile = () => {
    let { id } = useParams();
    const apiUrl = process.env.REACT_APP_API_URL ?? 'http://localhost:3000'
    const userSnap = useSnapshot(userState);
    const { backend } = useApi(true);
    const [user, setUser] = useState<UserWithRelations|null>(null);
    const [tips, setTips] = useState<TipWithRelations[] | CompareTipWithRelations[]>([] as TipWithRelations[]);
    const [compareMode, setCompareMode] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const loadProfile = () => {
        backend.ladder.ladderControllerGetUserInLadder(id).then((res) => {
            setUser(res.profile);
            setTips(res.tips);
        }).catch((err: ApiError) => {
            setErrorMessage(err.body.error.message);
        });
    }

    useEffect(() => {
        if(userSnap.isLoggedIn && userSnap.userProfile.entryFeePaid) {
            loadProfile();
        }
    },[userSnap.isLoggedIn]);

    if(!userSnap.isLoggedIn) {
        return(<><h1>Je vyžadováno přihlášení</h1></>);
    }


    const compareWithMe = () => {
        backend.ladder.ladderControllerCompareUserWithMe(id).then((comparedTips) => {
            setTips(comparedTips);
            setCompareMode(true);
            console.log(comparedTips);
        }).catch((err: ApiError) => {
            setErrorMessage(err.body.error.message);
        });
    }

    return (
        <>
            <Menu />
            <Breadcrumb text={'Uživatel / Profil'} showLogo={true} />
            <div className="container mx-auto p-1 md:p-4">
                {errorMessage && (
                    <Alert color="warning" rounded>
                        <span className="font-medium">Chyba!</span> {errorMessage}
                    </Alert>
                )}

                {user && (
                    <div className="card bg-gray-50 p-4 dark:bg-black dark:text-white">
                        <div className="flex flex-row gap-4">
                            <div className="w-1/3 text-center">
                                {user.avatar ? (
                                    <div className="relative inline-flex items-center justify-center w-20 h-20 md:w-36 md:h-36 overflow-hidden bg-gray-300 rounded-full dark:bg-gray-600">
                                        <img src={apiUrl + '/avatars/' + user.avatar} alt="avatar" className="w-full h-full object-cover" />
                                    </div>
                                ): (
                                    <div className="relative inline-flex items-center justify-center w-36 h-36 overflow-hidden bg-gray-300 rounded-full dark:bg-gray-600">
                                        <span className="text-5xl text-gray-600 dark:text-gray-500">{user.firstname?.charAt(0)}{user.lastname?.charAt(0)}</span>
                                    </div>
                                )}
                            </div>
                            <div className="w-2/3">
                                <h2 className="text-2xl font-bold">{user.firstname} {user.lastname}</h2>
                                <div className={'text-xl'}>Počet bodů: <span
                                    className={'font-bold'}>{user.points}</span></div>
                                <div><strong className={'font-bold'}>Natipováno zápasů:</strong> {tips.length}</div>
                                {user?.bonusQuestion && (
                                    <div>
                                        <div className={'font-bold'}>Bonusová otázka:</div>
                                        <div className={'flex flex-col md:flex-row text-center gap-2 items-center'}>
                                            <img src={'/images/flags/' + user.bonusQuestion.flag}
                                                 alt={user.bonusQuestion.nameCountry}
                                                 className='w-10'/> {user.bonusQuestion.nameCountry}

                                            {user?.bonusQuestionAnswer === 133909 && (
                                                <div className={'font-bold text-xl '}> + 50 b </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {tips.length > 0 && (
                    <div className="card bg-gray-50 dark:bg-black p-4 mt-4">
                        <div className={'flex flex-row justify-between m-1 items-center'}>
                            <h2 className="text-2xl font-bold">Tipy</h2>
                            {!compareMode ? (
                                <button
                                    className={'flex flex-row gap-2 bg-blue-500 text-white rounded px-1 py-0 md:px-2 md:py-1 items-center'}
                                    onClick={compareWithMe}><MdCompare/> Porovnat</button>
                            ) : (
                                <button
                                    className={'flex flex-row gap-2 bg-amber-400 text-white rounded px-1 py-0 md:px-2 md:py-1 items-center'}
                                    onClick={() => setCompareMode(false)}><GrCompare/> Zrušit</button>

                            )}
                        </div>
                        <table className="table-auto w-full">
                            <thead>
                            <tr>
                                <th className="border px-1 py-1 md:px-4 md:py-2">Domácí</th>
                                <th className="border px-1 py-1 md:px-4 md:py-2">Hosté</th>
                                <th className="border px-1 py-1 md:px-4 md:py-2">Tip</th>
                                <th className="border px-1 py-1 md:px-4 md:py-2">Výsledek</th>
                                <th className="border px-1 py-1 md:px-4 md:py-2">Body</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tips.map((tip, index) => (
                                <tr key={tip.id}>
                                    <td className="border px-1 py-1 md:px-4 md:py-2">
                                        <div className={'flex flex-col md:flex-row text-center gap-2 items-center'}>
                                            <img src={'/images/flags/' + tip.match?.teamHome.flag}
                                                 alt={tip.match?.teamHome.name}
                                                 className='w-10'/>
                                            {tip.match?.teamHome?.nameCountry}
                                        </div>
                                    </td>
                                    <td className="border px-1 py-1 md:px-4 md:py-2">
                                        <div className={'flex flex-col md:flex-row text-center gap-2 items-center'}>
                                            <img src={'/images/flags/' + tip.match?.teamAway.flag}
                                                 alt={tip.match?.teamAway.name}
                                                 className='w-10'/>
                                            {tip.match?.teamAway?.nameCountry}
                                        </div>
                                    </td>
                                    <td className="border px-1 py-1 md:px-4 md:py-2 w-28 text-center">
                                        <div>{tip.tipHomeTeam} : {tip.tipAwayTeam}</div>
                                        {compareMode && (
                                            <div className={'rounded bg-amber-300 text-black'}>
                                                {tip.compareTipHomeTeam} : {tip.compareTipAwayTeam}
                                            </div>
                                        )}
                                    </td>
                                    <td className="border px-1 py-1 md:px-4 md:py-2 w-20 text-center">
                                        {tip.match.locked && (
                                            <span>
                                                {tip.match.scoreHomeTeam} : {tip.match.scoreAwayTeam}
                                            </span>
                                        )}
                                    </td>
                                    <td className="border px-1 py-1 md:px-4 md:py-2">
                                        <div className={'flex flex-row gap-1 items-start text-center'}>
                                            {tip.points >= 0 && tip.points !== null ? (
                                                <div className={'flex flex-col'}>
                                                    <div className={'text-green-500 font-bold'}>{tip.points}</div>
                                                    {compareMode && (
                                                        <div className={'rounded bg-amber-300 text-black px-3'}>
                                                            {tip.comparePoints}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <Tooltip content={'Zápas ještě není vyhodnocen'}><span className={'text-red-500 font-bold cursor-no-drop'}>⏳</span></Tooltip>
                                            )}
                                            {tip.joker && (
                                                <JokerButton />
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}


                            </tbody>
                        </table>


                        {user?.bonusQuestionAnswer === 133909 && (
                            <div className={'flex flex-row-reverse mt-2'}>
                                <div className={'text-xl'}><Tooltip content={'Správně zodpovězená bonusová otázka'}>Bonusová otázka + 50b</Tooltip></div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <FooterCmp/>
        </>
    );
};
