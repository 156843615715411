'use client';
import { Modal } from 'flowbite-react';
import { useSnapshot } from 'valtio'
import { appState, userState } from '../store'
import { useState } from 'react'
import useApi from '../hooks/useApi'
import { ApiError, LoginRequest } from '../@backend'
import {useNavigate} from "react-router-dom";

export default function LoginModal() {
  const appSnap = useSnapshot(appState);
  const userSnap = useSnapshot(userState);
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors, setFormErrors] = useState([] as any);
  const [isSuccess, setIsSuccess] = useState(false);
  const { backend } = useApi(true);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  };


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const loginRes = await backend.user.userControllerLogin({email, password} as LoginRequest);
      setFormErrors([]);
      if(loginRes.token) {
        console.log(loginRes);
        userState.token = loginRes.token;
        userState.isLoggedIn = true;
        userState.userProfile = loginRes.userProfile;

        localStorage.setItem('token',loginRes.token);
        setIsSuccess(true);

        if(loginRes.userProfile.entryFeePaid === false) {
          navigate("/uzivatel/poplatek");
          appState.modals.showLoginModal = false;
        }
        setTimeout(() => {
          appState.modals.showLoginModal = false;
        }, 1000);
      }
      console.log('Login response:', loginRes);
    } catch (e) {
      if(e.status === 401) {
        setFormErrors(['Neplatné přihlašovací údaje']);
      }
    }

  };

      return (
        <>
        <Modal show={appSnap.modals.showLoginModal} onClose={() => appState.modals.showLoginModal = false} >
          <form onSubmit={handleSubmit}>
          <Modal.Header className={'dark:bg-black'}>Přihlášení</Modal.Header>
          <Modal.Body className={'dark:bg-black'}>
            <div className="flex flex-col w-full space-y-6">

              {isSuccess && (
                <div className='flex flex-col bg-green-200 border border-green-700 text-black px-4 py-3 rounded relative' role='form'>
                  <strong className='font-bold'>Úspěšné přihlášení!</strong>
                  {/*<span className='block sm:inline'></span>*/}
                </div>
              )}

              {formErrors.length > 0 && (
                <div className='flex flex-col bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
                     role='alert'>
                  <strong className='font-bold'>Problém s přihlášením!</strong>
                  <span className="block sm:inline">{formErrors.join(', ')}</span>
                </div>
              )}
                <div className={'flex flex-row gap-2 items-center place-content-center'}>
                  <label htmlFor="email" className="text-sm font-medium text-gray-700 dark:text-white">Email</label>
                  <input onChange={handleEmailChange} value={email} type="email" name="email" id="email" autoComplete="email" required className="input-box"  />
                </div>
                <div className={'flex flex-row  gap-2 items-center place-content-center'}>
                  <label htmlFor="password" className="text-sm font-medium text-gray-700  dark:text-white">Heslo</label>
                  <input onChange={handlePasswordChange} value={password} type="password" name="password" id="password" autoComplete="current-password" required className="input-box" />
                </div>
            </div>
          </Modal.Body>
            <Modal.Footer className='flex flex-row items-center place-content-center dark:bg-black'>
              <button type='button' className="default-button" onClick={() => appState.modals.showLoginModal = false}>
                Zrušit
              </button>
              <button type='submit' className="default-button">Přihlásit</button>
            </Modal.Footer>
          </form>
        </Modal>
        </>
      )
}
