export default [
    {
        id: '1tfjiELNrwYAJeafRYlT9RwOIiD',
        name: 'Grace Hopper'
    },
    {
        id: '1tfjiFoinFrbdLWlPI52dRLhNlD',
        name: 'Yoshitake Miura'
    },
    {
        id: '1tfjiDIAS8f2UYgV9ynCqWi7rZD',
        name: 'Ada Lovelace'
    },
    {
        id: '1tfjiEIWBZz2I9lOQYTEeMICALg',
        name: 'Grete Hermann'
    },
    {
        id: '1tfjiCMU9SdFM9BAaIF3mS5UpYf',
        name: 'Chieko Asakawa'
    }
].map((winner, position) => ({ ...winner, position }))
