'use client';
import { Modal } from 'flowbite-react';
import { useSnapshot } from 'valtio'
import { appState, userState } from '../store'
import { useState } from 'react'
import useApi from '../hooks/useApi'
import {ApiError, LoginRequest, NewUserRequest} from '../@backend'

export default function RegistrationModal() {
  const appSnap = useSnapshot(appState);
  const userSnap = useSnapshot(userState);

  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [email, setEmail] = useState(null);
  const [password1, setPassword1] = useState(null);
  const [password2, setPassword2] = useState(null);
  const [terms, setTerms] = useState(false);
  const [formErrors, setFormErrors] = useState([] as any);
  const [isSuccess, setIsSuccess] = useState(false);
  const { backend } = useApi(true);

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
  };
  const handlePassword1Change = (e: any) => {
    setPassword1(e.target.value);
  };
  const handlePassword2Change = (e: any) => {
    setPassword2(e.target.value);
  };
  const handleFirstnameChange = (e: any) => {
    setFirstname(e.target.value);
  };
  const handleLastnameChange = (e: any) => {
    setLastname(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {

      if(!terms) {
        setFormErrors(['Je potřeba souhlasit s podmínkami tipovací hry']);
        return;
      }

      if(password1.length <= 4) {
        setFormErrors(['Heslo musí mít alespoň 4 znaky']);
        return;
      }

      if(password1 !== password2) {
        setFormErrors(['Hesla se neshodují']);
        return;
      }

      if(firstname.length === 0 || lastname.length === 0) {
        setFormErrors(['Je potřeba vyplnit jméno a příjmení']);
        return;
      }

      const registrationRes = await backend.user.userControllerRegisterUser({email, password1, password2, firstname, lastname} as NewUserRequest);
      setFormErrors([]);
      if(registrationRes.id) {
        setIsSuccess(true);
        setTimeout(() => {
          appState.modals.showRegisterModal = false;
          appState.modals.showLoginModal = true;
          setFirstname(undefined);
          setLastname(undefined);
          setEmail(undefined);
          setPassword1(undefined);
          setPassword2(undefined);
          setIsSuccess(false);
          setTerms(false)
        }, 2000);
      }
    } catch (e) {
      if(e.status === 401) {
        setFormErrors(['Neplatné přihlašovací údaje']);
      }
      if(e.status === 409) {
        setFormErrors(['Uživatel s tímto emailem již existuje']);
      }
      if(e instanceof ApiError && e.status === 422) {
        setFormErrors([e.body.error.details[0].message]);
      }
    }

  };

      return (
          <>
            <form onSubmit={handleSubmit}>
              <Modal className={''} show={appSnap.modals.showRegisterModal} onClose={() => appState.modals.showRegisterModal = false}>
                <Modal.Header className={'h-14 md:h-16 dark:bg-black'}>Registrace</Modal.Header>
                <Modal.Body className={'dark:text-white dark:bg-black overflow-y-auto'}>
                  <div className="flex flex-col w-full space-y-2 md:space-y-4">

                    {isSuccess && (
                        <div
                            className='flex flex-col bg-green-200 border border-green-700 text-black px-4 py-3 rounded relative'
                            role='form'>
                          <strong className='font-bold'>Úspěšná registrace, nyní se můžete přihlásit!</strong>
                          {/*<span className='block sm:inline'></span>*/}
                        </div>
                    )}

                    {formErrors.length > 0 && (
                        <div
                            className='flex flex-col bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
                            role='alert'>
                          <strong className='font-bold'>Problém s registrací!</strong>
                          <span className="block sm:inline">{formErrors.join(', ')}</span>
                        </div>
                    )}
                    <div className={'flex flex-col md:flex-row gap-1'}>
                      <div className={'flex flex-col md:flex-row gap-0 md:gap-2 items-center place-content-center md:w-1/2'}>
                        <label htmlFor="firstname" className="text-sm font-medium text-gray-700 dark:text-white">Jméno</label>
                        <input onChange={handleFirstnameChange} type="text" name="firstname"
                               id="firstname"
                               autoComplete="firstname"
                               placeholder={'Jméno'}
                               required className="input-box dark:bg-black dark:text-white"/>
                      </div>
                      <div className={'flex flex-col md:flex-row gap-0 md:gap-2 items-center place-content-center md:w-1/2'}>
                        <label htmlFor="lastname" className="text-sm font-medium text-gray-700 dark:text-white">Příjmení</label>
                        <input onChange={handleLastnameChange} type="text" name="lastname"
                               id="lastname" autoComplete="lastname"
                               placeholder={'Příjmení'}
                               required className="input-box dark:bg-black dark:text-white"/>
                      </div>
                    </div>

                    <div className={'flex flex-col md:flex-row gap-1 md:w-full'}>
                      <div className={'flex flex-col md:flex-row gap-0 md:gap-2 md:ml-4 md:mr-1 items-center md:w-full'}>
                        <label htmlFor="email" className="text-sm font-medium text-gray-700 dark:text-white">Email</label>
                        <input onChange={handleEmailChange} type="email" name="email" id="email"
                               placeholder={'vas@email.cz'} autoComplete="email" className="input-box md:w-full dark:bg-black dark:text-white" required />
                      </div>
                    </div>

                    <div className={'flex flex-col md:flex-row gap-1 md:gap-2'}>
                      <div className={'flex flex-col md:flex-row gap-0 md:gap-2 items-center place-content-center'}>
                        <label htmlFor="password1" className="text-sm font-medium text-gray-700 dark:text-white">Heslo</label>
                        <input onChange={handlePassword1Change} placeholder={'Zadejte heslo'}
                               type="password" name="password1" id="password1"
                               autoComplete="current-password" required className="input-box dark:bg-black dark:text-white"/>
                      </div>

                      <div className={'flex flex-col md:flex-row gap-0 md:gap-2 items-center place-content-center'}>
                        <label htmlFor="password2" className="text-sm font-medium text-gray-700 dark:text-white">Heslo znovu</label>
                        <input onChange={handlePassword2Change} placeholder={'Opakujte Vaše heslo'}
                               type="password" name="password2" id="password2"
                               autoComplete="current-password" required className="input-box dark:bg-black dark:text-white"/>
                      </div>
                    </div>
                    <div>
                      <input onChange={(e) => setTerms(e.target.checked)} type="checkbox"/> Souhlasím s <a href={'/pravidla'} className={'underline text-blue-600 cursor-pointer'} target={'_blank'} rel="noreferrer"> pravidly tipovací soutěže</a> a podmínkami pro <a href={'/gdpr'} className={'underline text-blue-600 cursor-pointer'} target={'_blank'} rel="noreferrer">zpracování osobních údajů</a>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className='flex flex-row items-center place-content-center h-16 dark:bg-black'>
                  <button type='button' className="default-button"
                          onClick={() => appState.modals.showRegisterModal = false}>
                    Zrušit
                  </button>
                  <button type='submit' disabled={isSuccess} onClick={handleSubmit} className="default-button">Registrace</button>
                </Modal.Footer>
              </Modal>
            </form>

          </>
      )
}
