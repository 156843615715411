import React from 'react'

export function JokerButton({selected = null, onClick = null, dark = false}) {

    const getFill = () => {
      if(selected) {
        return '#ff8b00';
      }
      if(dark) {
        return '#ffffff';
      }
        return '#000000';
    }

  return (
   <button className='joker-button w-5 h-5 cursor-pointer' onClick={onClick}>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                 width="22px" height="22px" viewBox="0 0 512.000000 464.000000"
                 preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,464.000000) scale(0.100000,-0.100000)" fill={getFill()} stroke="none">
                    <path d="M1670 4614 c-233 -50 -422 -201 -557 -444 -53 -94 -52 -96 13 -103
                    82 -8 154 -48 220 -120 l57 -63 66 23 c90 31 145 30 212 -4 69 -36 119 -102
                    158 -210 49 -133 81 -353 81 -548 l0 -93 -116 59 c-133 67 -312 128 -469 159
                    -137 27 -393 38 -517 21 -376 -50 -637 -240 -757 -551 -42 -108 -65 -230 -58
                    -311 10 -123 88 -279 215 -430 l56 -67 44 50 c42 46 124 98 155 98 10 0 16 21
                    20 79 12 187 126 311 284 311 153 0 291 -107 390 -304 l35 -69 -11 -136 c-24
                    -289 28 -653 135 -946 112 -308 246 -542 411 -716 251 -264 560 -350 883 -247
                    498 159 887 817 961 1623 12 134 8 258 -13 381 l-11 71 33 48 c81 117 212 221
                    330 261 74 26 224 25 300 0 111 -37 246 -124 333 -214 l39 -40 59 31 c47 24
                    75 31 139 35 l80 5 -6 56 c-43 418 -182 707 -426 892 -330 248 -805 275 -1307
                    73 -47 -19 -85 -33 -86 -31 0 1 -10 61 -22 132 -94 571 -469 1059 -928 1209
                    -146 47 -297 58 -425 30z m273 -2902 c69 -34 155 -111 181 -161 37 -70 -11
                    -101 -158 -101 -158 0 -404 51 -483 100 -59 36 -37 93 55 143 79 43 146 57
                    249 53 79 -3 102 -8 156 -34z m1199 18 c110 -34 181 -96 159 -139 -50 -92
                    -585 -194 -661 -126 -25 23 -25 51 0 92 44 74 146 151 233 178 65 21 194 18
                    269 -5z m-322 -914 c-44 -133 -148 -252 -266 -308 -57 -27 -77 -31 -148 -31
                    -95 1 -161 22 -255 83 -115 74 -198 187 -217 293 l-7 38 79 -39 c134 -67 268
                    -102 386 -102 120 0 294 52 399 119 24 15 45 24 47 19 2 -4 -6 -37 -18 -72z"/>
                    <path d="M963 3952 c-126 -45 -195 -141 -195 -273 0 -115 56 -205 160 -256
117 -57 251 -29 341 73 95 108 93 263 -4 372 -80 88 -197 121 -302 84z"/>
                    <path d="M4717 2160 c-67 -12 -131 -52 -152 -95 -60 -127 40 -375 179 -448
126 -65 317 27 361 176 34 113 10 206 -75 289 -83 81 -167 102 -313 78z"/>
                    <path d="M470 1983 c-64 -23 -142 -105 -165 -172 -21 -64 -17 -169 9 -218 68
-133 217 -194 357 -148 70 24 149 101 172 169 38 111 13 221 -67 302 -81 80
-196 105 -306 67z"/>
                </g>
            </svg>
    </button>
  )
}
