import { backendApi } from '../@backend'


const useApi = (useJWT: boolean = true) => {
    let token: string  | undefined = undefined;
    if(useJWT) {
        if (typeof window !== 'undefined') {
            token = localStorage.getItem('token') ?? undefined;
        }
    }
    const backend = new backendApi({
        BASE: process.env.REACT_APP_API_URL ?? 'http://localhost:3000',
        // BASE: 'https://api.tipbuddy.cz',
        TOKEN: token,
    });
    return {backend};
}

export default useApi;
