/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AdminService } from './services/AdminService';
import { LadderService } from './services/LadderService';
import { MatchesService } from './services/MatchesService';
import { TeamsService } from './services/TeamsService';
import { TimeControllerService } from './services/TimeControllerService';
import { TipsService } from './services/TipsService';
import { UserService } from './services/UserService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class backendApi {
    public readonly admin: AdminService;
    public readonly ladder: LadderService;
    public readonly matches: MatchesService;
    public readonly teams: TeamsService;
    public readonly timeController: TimeControllerService;
    public readonly tips: TipsService;
    public readonly user: UserService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://localhost:3000',
            VERSION: config?.VERSION ?? '0.0.1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.admin = new AdminService(this.request);
        this.ladder = new LadderService(this.request);
        this.matches = new MatchesService(this.request);
        this.teams = new TeamsService(this.request);
        this.timeController = new TimeControllerService(this.request);
        this.tips = new TipsService(this.request);
        this.user = new UserService(this.request);
    }
}

