import {Menu} from "../ui/layout/Menu";
import React, {useEffect} from "react";
import {Breadcrumb} from "../ui/Breadcrumb";
import {User} from "../@backend";
import useApi from "../hooks/useApi";
import {FooterCmp} from "../ui/layout/Footer";
import {InfoBox} from "../ui/InfoBox";
import {userState} from "../store";
import {useSnapshot} from "valtio";
import {AiFillCheckCircle, AiFillPayCircle} from "react-icons/ai";
import {Link} from "react-router-dom";
import {MdEmail} from "react-icons/md";
import {FaCcPaypal} from "react-icons/fa";
import {PrizeMoney} from "../ui/PrizeMoney";
import Podium from "../ui/podium/Podium";
import podiumData from "../fixtures/podiumData";

export const Ladder = () => {

    const userSnap = useSnapshot(userState);
    const apiUrl = process.env.REACT_APP_API_URL ?? 'http://localhost:3000';
    const [users, setUsers] = React.useState<User[]>([]);
    const {backend} = useApi();

    const loadLadder = () => {
        backend.ladder.ladderControllerGetAllUsersInLadder().then((resUsers) => {
            setUsers(resUsers);
        });
    }

    useEffect(() => {
    // && userSnap.userProfile.entryFeePaid
        if(userSnap.isLoggedIn) {
            loadLadder();
        }
    },[userSnap.isLoggedIn]);


    const payEntryFee = (userId: string) => {
        backend.user.userControllerPayEntryFee(userId).then((res) => {
            alert('Zaplaceno');
            loadLadder();
        });
    }

    const sendPaymentInfo = (userId: string) => {
        backend.user.userControllerSendPaymentInfo(userId).then((res) => {
            alert('Platební údaje byly odeslány na email');
        });
    }


    return (
        <>
        <Menu/>
        <Breadcrumb text={'Žebříček'} showLogo={true}/>

            {/*&& userSnap.userProfile.entryFeePaid*/}

            {/*<PrizeMoney showRegisterButton={!userSnap.isLoggedIn}/>*/}

            {userSnap.isLoggedIn ? (
            <>
            <h1 className={'text-center text-2xl font-bold mt-4'}>Konečné pořadí</h1>
            <Podium winners={podiumData} />
            <div className={'flex flex-col text-center mt-5 place-items-center place-content-center'}>
                <div className={'font-bold text-xl'}>Výhry</div>
                <table className={'w-1/3'}>
                    <tr>
                        <td>1. Místo</td>
                        <td>1000 Kč</td>
                    </tr>
                    <tr>
                        <td>2. Místo</td>
                        <td>600 Kč</td>
                    </tr>
                    <tr>
                        <td>3. Místo</td>
                        <td>400 Kč</td>
                    </tr>
                </table>

                <div className={'font-bold p-4'}>
                    Všem hráčům děkujeme za účast a těšíme se na další ročníky.
                </div>
            </div>

                <div className={'flex flex-row justify-center gap-2 w-full md:w-1/2 mx-auto'}>
                <div className="container mx-auto p-4 card bg-gray-50 dark:bg-black dark:text-white">
                    <div className={'flex flex-col gap-1'}>
                        <div className={'flex flex-row bg-gray-200 px-2 py-4 font-bold gap-2 dark:bg-gray-800 dark:text-white rounded-2xl'}>
                            <div className={'w-1/12'}>Pořadí</div>
                            <div className={'w-4/12'}>Jméno a příjmení</div>
                            <div className={'w-2/12'}>Body</div>
                            {userSnap.userProfile.role === 'Admin' && (
                                <>
                                    <div className={'w-2/12'}>Zaplaceno</div>
                                    <div className={'w-1/4'}>Akce</div>
                                </>
                            )}
                        </div>
                        {users.map((user, index) => (
                            <div key={user.id} className={'flex flex-row px-2 gap-2 items-center md:hover:bg-gray-200 dark:hover:bg-gray-800'}>
                                <div className={'w-1/12 py-2'}>{index + 1}</div>
                                <div className={'w-4/12 py-2 flex flex-row gap-2 items-center'}>
                                    {user.avatar ? (
                                        <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-300 rounded-full dark:bg-gray-600">
                                            <img src={apiUrl + '/avatars/' + user.avatar} alt="avatar" className="w-full h-full object-cover" />
                                        </div>
                                    ): (
                                        <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-300 rounded-full dark:bg-gray-600">
                                            <span className="text-normal text-gray-600 dark:text-gray-500">{user.firstname?.charAt(0)}{user.lastname?.charAt(0)}</span>
                                        </div>
                                    )}
                                    {user.publicProfile ? (
                                        <Link className={'text-blue-500 underline'} to={`/uzivatel/profil/${user.id}`}>{user.firstname} {user.lastname}</Link>
                                    ) : (
                                        <p>{user.firstname} {user.lastname}</p>
                                    )}
                                </div>
                                <div className={'w-2/12 py-2'}>{user.points}</div>
                                {userSnap.userProfile.role === 'Admin' && (
                                    <>
                                    <div className={'w-2/12'}>{user.entryFeePaid && (
                                        <AiFillCheckCircle className={'text-green-500'}/>
                                    )}
                                    </div>
                                    <div className={'w-1/4 flex flex-row gap-1'}>
                                        {!user.entryFeePaid && (
                                            <>
                                                <button onClick={(e) => payEntryFee(user.id)}
                                                        className={'bg-blue-500 text-white rounded px-1 py-0 md:px-2 md:py-1 flex flex-row items-center'}>
                                                    <AiFillPayCircle/> Zaplatit
                                                </button>
                                                <button onClick={(e) => sendPaymentInfo(user.id)}
                                                        className={'bg-blue-500 text-white rounded px-1 py-0 md:px-2 md:py-1 flex flex-row items-center'}>
                                                    <MdEmail/> Platební údaje
                                                </button>
                                            </>
                                        )}

                                    </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
</>
            ) : (
                <div className={'text-center mt-10'}>
                <h1 className={'text-3xl font-bold text-center'}>
                Pouze pro registravané hráče...
            </h1>
            <h1 className={'text-sm font-bold text-center'}>
                Pro zobrazení žebříčku se přihlašte a zaplaťte registrační poplatek.
            </h1>
            </div>
    )
}

    <FooterCmp/>
</>
)
};
