import { AiOutlineUser } from 'react-icons/ai'
import React from 'react'
import { appState } from '../../store'

export function UserLoginButton() {

  return (
    <>
      <button
        onClick={() => {appState.modals.showLoginModal = true}}
        className="default-icon-button">
        <AiOutlineUser size="1.3em" />
          <span className={'hidden md:inline'}>Přihlásit se</span>
      </button>
    </>
  )
}
