import {Menu} from "../ui/layout/Menu";
import React from "react";
import {Breadcrumb} from "../ui/Breadcrumb";
import {FooterCmp} from "../ui/layout/Footer";
import {JokerButton} from "../ui/buttons/JokerButton";

export const Rules = () => {
    return (
        <>
            <Menu />
            <Breadcrumb text={'Pravidla'} showLogo={true} />
            <div className="container mx-auto p-4 card bg-gray-50 rules dark:bg-black">
                <ol className={'list-decimal pl-2 md:pl-8'}>
                    <li><span className={'text-lg font-bold'}>Registrace</span>
                        <ol className={'list-decimal pl-8'}>
                            <li>Registrace je možná od 18. let.</li>
                            <li>Účet se stává aktivním až po uhrazení registračního poplatku.</li>
                            <li>Uhrazení registračního poplatku bude možné do prvního zápasu turnaje.</li>
                            <li>Je možné si zaregistrovat více účtů, poplatek je třeba uhradit poplatek za každou
                                registraci.
                            </li>
                            <li>Provozovatel si vyhrazuje účet zrušit s řádným odůvodněním.</li>
                        </ol>
                    </li>

                    <li><span className={'text-lg font-bold'}>Poplatky</span>
                        <ol className={'list-decimal pl-8'}>
                            <li>
                                Za vstup do soutěže je vybírán poplatek 100 Kč.
                            </li>
                            <li>Částku můžete předat osobně, přes prostředníka nebo zasláním na účet.
                                Platební údaje včetně variabilního symbolu najdete v sekci profil. (pravá horní část)
                            </li>
                        </ol>
                    </li>


                    <li><span className={'text-lg font-bold'}>Tipy</span>
                        <ol className={'list-decimal pl-8'}>
                            <li>Tip není penežní sázka na zápas</li>
                            <li>V soutěži se tipují výsledky všech zápasů.</li>
                            <li>Vždy se tipují přesné výsledky utkání po standardní hrací době, tzn. po 90 minutách.
                            </li>
                            <li>Tip je možné libovolně měnit do začátku zápasu.</li>
                        </ol>
                    </li>

                    <li><span className={'text-lg font-bold'}>Bonusová otázka</span>
                        <ol className={'list-decimal pl-8'}>
                            <li>
                                Do 17.6.2024 23:59:59 je možné odpovědět na bonusovou otázku.
                            </li>
                            <li>
                                Za správnou odpověď získáte 50 bodů.
                            </li>
                            <li>
                                Vyhodnocení probíhá po skončení turnaje.
                            </li>
                        </ol>
                    </li>


                    <li><span className={'text-lg font-bold'}>Vyhodnocení tipů utkání</span>
                        <ol className={'list-decimal pl-8'}>
                            <li>Přesný tip výherce a skóre je 20 bodů</li>
                            <li>Tip výherce a gólový rozdíl je 10 bodů</li>
                            <li>Tip výherce je 5 bodů</li>
                            <div className={'pl-5 mt-2'}>
                                    <b>PŘÍKLADY</b><br/>
                                    <table
                                        className={'w-1/2 text-sm text-left rtl:text-right text-black dark:text-gray-400'}>
                                        <thead
                                            className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <th scope="col" className="px-2 py-3">
                                            VÝSLEDEK
                                        </th>
                                        <th scope="col" className="px-2 py-3">
                                            TIP
                                        </th>
                                        <th scope="col" className="px-2 py-3">
                                            HODNOCENÍ
                                        </th>
                                        </thead>

                                        <tbody>
                                        <tr>
                                            <td>4:2</td>
                                            <td>4:2</td>
                                            <td>přesný tip — 20 bodů</td>
                                        </tr>
                                        <tr>
                                            <td>4:2</td>
                                            <td>3:2</td>
                                            <td>správný tip — výhra — 5 bodů</td>
                                        </tr>
                                        <tr>
                                            <td>4:2</td>
                                            <td>3:1</td>
                                            <td>správný tip — výhra a uhodnutý rozdíl ve skóre — 10 bodů</td>
                                        </tr>
                                        <tr>
                                            <td>4:2</td>
                                            <td>2:4</td>
                                            <td>nesprávný tip — prohra — 0 bodů</td>
                                        </tr>
                                        <tr>
                                            <td>2:2</td>
                                            <td>3:3</td>
                                            <td>správný tip — remíza — 10 bodů</td>
                                        </tr>
                                        <tr>
                                            <td>2:2</td>
                                            <td>2:2</td>
                                            <td>přesný tip — 20 bodů</td>
                                        </tr>
                                        <tr>
                                            <td>2:2</td>
                                            <td>2:1</td>
                                            <td>nesprávný tip — 0 bodů</td>
                                        </tr>
                                        </tbody>
                                    </table>
                            </div>
                        </ol>
                    </li>

                    <li><span className={'text-lg font-bold'}>Odměny</span>
                        <ol className={'list-decimal pl-8'}>
                            <li>Celková odměna je počet registračních poplatků a dalších zdrojů například sponzorských
                                darů.
                            </li>
                            <li>Celková odměna závisí na počtu registrovaných lidí a může se měnit do začátku turnaje.
                            </li>
                            <li>Celková odměna se rozdělí mezi hráče podle úmístění v žebříčku
                                <div className={'pl-5 mt-2'}>
                                    Příklad rozdělení odměny při celkové odměně 6400 Kč<br/>

                                    1. místo 3 000 Kč<br/>
                                    2. místo 1 500 Kč<br/>
                                    3. místo 1 000 Kč<br/>
                                    4. místo 500 Kč<br/>
                                    5. místo 300 Kč<br/>
                                    6. místo 100 Kč<br/>
                                    ...
                                </div>
                            </li>
                            <li>Provozovatel si vyhrazuje upravovat rozdělení cen do konce turnaje v závislosti na výši
                                celkové odměny.
                            </li>
                            <li> Pořadí mezi hráči se stejnými body se určuje podle následujících kritérií:

                                <ol className={'list-decimal pl-8'}>
                                    <li>Správně zodpovězená bonusová otázka</li>
                                    <li>Větší počet tipů za 20 bodů</li>
                                    <li>Větší počet tipů za 10 bodů</li>
                                    <li>Větší bodová hodnota za zápas, u kterého byl aktivován žolík</li>
                                    <li>Los</li>
                                </ol>
                            </li>
                            <li>Odměny budou vyplaceny na účet nebo předány osobně výhercům nejpozději do 14 dnů od skončení
                                turnaje.
                            </li>
                        </ol>
                    </li>

                    <li><span className={'text-lg font-bold'}>Žolík</span>
                        <ol className={'list-decimal pl-8'}>
                            <li>
                                Každý hráč může využít třikrát žolíka. Stačí označit zápas aktivováním symbolu žolíka
                                ( <JokerButton /> ) u konkrétního zápasu.
                            </li>
                            <li>Použitím žolíku se body zdvojnásobí.</li>
                            <li>Žolík je možné libovolně měnit do začátku zápasu.</li>
                        </ol>
                    </li>


                    <li><span className={'text-lg font-bold'}>Další ujednání</span>
                        <ol className={'list-decimal pl-8'}>
                            <li>
                                Pořadatel si vyhrazuje právo soutěž kdykoliv zrušit. Vstupní poplatek bude vrácen na
                                účet
                            </li>
                            <li>Účastník si je vědom povahy hry a považuje ji za zábavu nikoliv za hazardní hru.</li>
                            <li>Pokud bude zjištěna závažná závislost na hazardních hrách, může pořadatel odepřít účast
                                v soutěži.
                            </li>
                            <li>Jakákoli snaha o podvod nebo manipulaci s výsledky bude důvodem k diskvalifikaci.</li>
                        </ol>
                    </li>
                </ol>
            </div>
            <FooterCmp/>
        </>
    );
};
