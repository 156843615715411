/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewTip } from '../models/NewTip';
import type { Tip } from '../models/Tip';
import type { TipWithRelations } from '../models/TipWithRelations';
import type { UpdateTip } from '../models/UpdateTip';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TipsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody Create new tip
     * @returns Tip
     * @throws ApiError
     */
    public tipControllerAddTip(
        requestBody?: NewTip,
    ): CancelablePromise<Tip> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tips/new',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any Return value of TipController.updateTip
     * @throws ApiError
     */
    public tipControllerUpdateTip(
        requestBody?: UpdateTip,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/tips/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns boolean
     * @throws ApiError
     */
    public tipControllerToggleJoker(
        requestBody?: {
            tipId?: string;
        },
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/tips/{id}/toggle-joker',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns TipWithRelations Array of Match model instances
     * @throws ApiError
     */
    public tipControllerGetAllTips(): CancelablePromise<Array<TipWithRelations>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tips',
        });
    }
}
