import { motion } from 'framer-motion'
import {Tooltip} from "flowbite-react";

export default function PodiumStep({ podium, winner }) {
    const offset = podium.length - winner.position
    let delay;
    if(offset === 1) {
        delay = 0.1;
    }else{
        delay = 0.2 + (offset + 0.5);
    }

    console.log(podium.length - winner.position);
    console.log('Delay:' +  0.2 + (offset + 2));
    return (
        <div style={{
                display: 'flex',
                flexDirection: 'column',
                placeContent: 'center',
                placeItems: 'center',
            }}>
            <motion.div
                style={{
                    alignSelf: 'center',
                    marginBottom: '.25rem'
                }}
                initial="hidden"
                animate="visible"
                variants={{
                    visible: {
                        opacity: 1,
                        transition: {
                            delay: delay,
                            duration: 0.35
                        }
                    },
                    hidden: { opacity: 0 }
                }}>
                <div className={'flex flex-col text-center font-bold'}>
                    <Tooltip content={`${winner.points} bodů`}>
                    <div>{winner.firstname}</div>
                    <div>{winner.lastname}</div>
                    </Tooltip>
                </div>
                {/*<img*/}
                {/*    src={`https://via.placeholder.com/150`}*/}
                {/*    alt=""*/}
                {/*    style={{*/}
                {/*        width: '2.75rem',*/}
                {/*        overflow: 'hidden',*/}
                {/*        height: '2.75rem',*/}
                {/*        borderRadius: 9999*/}
                {/*    }}*/}
                {/*/>*/}
            </motion.div>

            <motion.div style={{
                    width: '4rem',
                    placeContent: 'center',
                    display: 'flex',
                    borderTopLeftRadius: '.5rem',
                    borderTopRightRadius: '.5rem',
                    borderColor: 'rgb(35,99,204)',
                    backgroundColor: 'rgb(63 131 248)',
                    marginBottom: -1,
                    filter: `opacity(${0.1 + offset / podium.length})`
                }}
                initial="hidden"
                animate="visible"
                variants={{
                    visible: {
                        height: 200 * (offset / podium.length),
                        opacity: 1,
                        transition: {
                            delay: delay,
                            duration: 2,
                            ease: 'backInOut'
                        }
                    },
                    hidden: { opacity: 0, height: 0 }
                }}
            >
        <span style={{ color: 'white', alignSelf: 'flex-end' }}>
          {winner.position + 1}
        </span>
            </motion.div>
        </div>
    )
}