/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddMatch } from '../models/AddMatch';
import type { CloseMatch } from '../models/CloseMatch';
import type { Match } from '../models/Match';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AdminService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns any Return value of AdminController.adminAddUsers
     * @throws ApiError
     */
    public adminControllerAdminAddUsers(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/add-users',
        });
    }
    /**
     * @returns any Return value of AdminController.adminDownloadTeamLogos
     * @throws ApiError
     */
    public adminControllerAdminDownloadTeamLogos(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/download-teamlogos',
        });
    }
    /**
     * @returns any Return value of AdminController.adminGetEvents
     * @throws ApiError
     */
    public adminControllerAdminGetEvents(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/import-events',
        });
    }
    /**
     * @returns any Return value of AdminController.adminGetTeams
     * @throws ApiError
     */
    public adminControllerAdminGetTeams(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/import-teams',
        });
    }
    /**
     * @param requestBody
     * @returns Match Retun new Match instance
     * @throws ApiError
     */
    public matchControllerAddMatch(
        requestBody?: AddMatch,
    ): CancelablePromise<Match> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/matches/add',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any Return value of MatchController.closeMatch
     * @throws ApiError
     */
    public matchControllerCloseMatch(
        requestBody?: CloseMatch,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admin/matches/close',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any Return value of AdminController.adminPointsCorrection
     * @throws ApiError
     */
    public adminControllerAdminPointsCorrection(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/points/correction',
        });
    }
    /**
     * @returns any Return value of AdminController.adminSendEuro2024Email
     * @throws ApiError
     */
    public adminControllerAdminSendEuro2024Email(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/send/email/euro-2024',
        });
    }
    /**
     * @returns any Return value of AdminController.adminSentTestEmail
     * @throws ApiError
     */
    public adminControllerAdminSentTestEmail(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/admin/send-test-email',
        });
    }
    /**
     * @returns boolean
     * @throws ApiError
     */
    public userControllerSendReminderEmail(): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/admin/users/send-reminder-emails',
        });
    }
    /**
     * @param id
     * @returns boolean Retun new Match instance
     * @throws ApiError
     */
    public userControllerPayEntryFee(
        id: string,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/admin/users/{id}/pay-entry-fee',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns boolean Retun new Match instance
     * @throws ApiError
     */
    public userControllerSendPaymentInfo(
        id: string,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/admin/users/{id}/send-payment-info',
            path: {
                'id': id,
            },
        });
    }
}
