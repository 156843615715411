/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Team } from '../models/Team';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TeamsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param sport
     * @returns Team Array of Team model instances
     * @throws ApiError
     */
    public teamsControllerGetAllTeams(
        sport?: string,
    ): CancelablePromise<Array<Team>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/teams',
            query: {
                'sport': sport,
            },
        });
    }
}
