import {AiOutlinePlus} from 'react-icons/ai'
import React from 'react'
import { appState } from '../../store'

export function UserRegisterButton({smHideText = true}) {
  // const appSnap = useSnapshot(appState);

  return (
    <>
      <button
        onClick={() => {appState.modals.showRegisterModal = true}}
        className="default-icon-button">
        <AiOutlinePlus size="1.3em" />
          <span className={smHideText ? 'hidden md:inline' : ''}>Registrovat se</span>
      </button>
    </>
)
}
