import {Menu} from "../ui/layout/Menu";
import React from "react";
import {Breadcrumb} from "../ui/Breadcrumb";
import {FooterCmp} from "../ui/layout/Footer";

export const Contact = () => {
    return (
        <>
            <Menu />
            <Breadcrumb text={'Kontakt'} showLogo={true} />
                <div className="container mx-auto p-4 card bg-gray-50 dark:bg-black">
                    <div className={'flex flex-col text-center place-content-center min-h-96'}>
                        <h3 className={'text-2xl'}>V případě jakýchkoliv dotazů neváhejte mě kontaktovat: </h3>
                        <h2 className={'text-3xl'}><a href={'mailto:info@tipbuddy.cz'} className={'text-blue-600'}>info@tipbuddy.cz</a></h2>
                    </div>
                </div>
            <FooterCmp />
        </>
    );
};
