/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginRequest } from '../models/LoginRequest';
import type { LoginRes } from '../models/LoginRes';
import type { NewUser } from '../models/NewUser';
import type { UpdateUser } from '../models/UpdateUser';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns boolean
     * @throws ApiError
     */
    public userControllerSendReminderEmail(): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/admin/users/send-reminder-emails',
        });
    }
    /**
     * @param id
     * @returns boolean Retun new Match instance
     * @throws ApiError
     */
    public userControllerPayEntryFee(
        id: string,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/admin/users/{id}/pay-entry-fee',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns boolean Retun new Match instance
     * @throws ApiError
     */
    public userControllerSendPaymentInfo(
        id: string,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/admin/users/{id}/send-payment-info',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestBody
     * @returns LoginRes User
     * @throws ApiError
     */
    public userControllerLogin(
        requestBody?: LoginRequest,
    ): CancelablePromise<LoginRes> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param prompt
     * @returns any OK
     * @throws ApiError
     */
    public userControllerGenerateAvatar(
        prompt?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/my/profile/generate-avatar',
            query: {
                'prompt': prompt,
            },
        });
    }
    /**
     * @param requestBody
     * @returns boolean Array of Match model instances
     * @throws ApiError
     */
    public userControllerUpdateMyProfile(
        requestBody?: UpdateUser,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/my/profile/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns User Return current user id
     * @throws ApiError
     */
    public userControllerMyProfile(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/my/profile',
        });
    }
    /**
     * @returns string Test function
     * @throws ApiError
     */
    public userControllerUserPaymentQrCode(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/users/payment-qr-code',
        });
    }
    /**
     * @param requestBody
     * @returns User Array of Match model instances
     * @throws ApiError
     */
    public userControllerRegisterUser(
        requestBody?: NewUser,
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/register',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns boolean Return boolean if request is saved
     * @throws ApiError
     */
    public userControllerAnswerBonusQuestion(
        requestBody?: {
            answer?: number;
        },
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/users/{id}/bonus-question',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
